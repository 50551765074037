<template>
  <div
    class="flex flex-col self-center justify-center w-full h-full max-w-sm px-4 mx-auto"
  >
    <h1 class="mb-8 text-2xl font-black text-center">
      {{ $t("auth.forgot-password") }}
    </h1>

    <transition name="fade">
      <div
        v-if="Object.values(form.errors || {}).length || form_message.length"
      >
        <p v-if="!!form_message" 
          class="p-4 mb-4 text-sm text-white rounded-md" 
          :class="{
            'bg-red-400' : form.errors.has('email'),
            'bg-green-400' : !form.errors.has('email')
          }"
        >{{ form_message }}</p>
      </div>
    </transition>

    <form
      @submit.prevent="forgotAction"
      @keydown="form.onKeydown($event)"
      name="forgot-password"
      class="flex flex-col space-y-2"
      aria-autocomplete="off"
      autocomplete="off"
    >
      <div class="flex flex-col space-y-2">
        <t-input-group :label="$t('auth.form.email')">
          <t-input
            v-model="form.email"
            required
            type="email"
            name="email"
            :variant="{ danger: form.errors.has('email') }"
          />
        </t-input-group>

        <t-button type="submit" :disabled="form.busy">{{
          form.busy ? $t("action.loading") : $t("auth.btn--request-password-reset")
        }}</t-button>
      </div>
    </form>

    <div class="mt-8 space-y-4 font-medium text-center">
      <p>
        <router-link variant="link" is="t-button" :to="{ name: 'login' }">{{
          $t("auth.login")
        }}</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { Form } from "vform";
import api from "utils/api";

export default {
  name: "Forgot",

  data() {
    return {
      form: new Form({
        email: "",
      }),
      form_loading: false,
      form_message: null
    };
  },
  methods: {
    forgotAction() {
      const { email } = this.form;

      this.form_loading = true;
      this.form_message = null;

      if (this.form.errors.any()) {
        this.form_loading = false;
        return;
      }

      api.post("api/forgot-password", { email })
        .then((response) => {
          this.form_message = response.data.message || this.$i18n.t('alerts.success');
          this.form_loading = false;
        })
        .catch(err => {
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 422:
                this.form.errors.set(err.response.data.errors); // assign field errors
                this.form_message = err.response.data.message;
                return;
            }
            this.form_message = this.$i18n.t('unknown_error');
          }
          this.form.errors.set([]);
        })
        .then(() => {
          this.form_loading = false;
        });
    }
  }
};
</script>
